(function($){
	const htmlHasClass = (_class) => {
		return $('html').hasClass(_class);
	};
	const siteName = 'hakutsuru';
	const a = 'is-active';
	const v = 'is-visible';
	const f = 'is-fixed';
	const slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			isGlobal: location.pathname.indexOf('/global/'),
			region: location.pathname.indexOf('/global/') === -1 ? 'jp' : location.pathname.split('/')[2],
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision(){
			var regExp = new RegExp(`(test\\.${siteName}\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.)`, 'g');
			return regExp.test(location.hostname);
		},
		localLoading(){
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll(`[class*="${key}"]`);
			var includeClassLen = includeClass.length;

			for(i=0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if(ajaxLen > 0) {
				for(i=0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i]+'.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done((...args) => {
					var regExp = new RegExp(key);

					for(i=0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for(j=0; j < position.length; j++) {
							if(position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.'+position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError(_errorThrown){
			if(window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery(){
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for(var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle(){
			var $switch = $('.js-toggle');

			$switch.on('click', function(){
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll(){
			$('a[rel="scroll"]').on('click', (event) => {
				var speed = 1200;
				if(/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed){
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if(hash !== '#pagetop') {
					if (FUNCTIONS.va.isGlobal === -1) {
						if(FUNCTIONS.va.window.width <= FUNCTIONS.va.device.sp) {
							pos -= 60;
						} else {
							pos -= 100;
						}
					} else {
						if (FUNCTIONS.va.window.width <= FUNCTIONS.va.device.sp) {
							pos -= 60;
						} else {
							pos -= 90;
						}
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash() {
			const hash = location.hash;
			if (hash) {
				$(window).on('load', () => {
					$('html, body').css('display', 'none');

					setTimeout(() => {
						$('html, body').css('display', 'block');

						let _hash = '#' + hash.split('#')[1];
						let pos = $(_hash).offset().top;

						if (FUNCTIONS.va.window.width <= FUNCTIONS.va.device.sp) {
							pos -= 60;
						} else {
							pos -= 100;
						}
						$('html, body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');

						// よくあるご質問
						if (location.pathname.indexOf('/customer/faq/') !== -1 && hash.indexOf('faq') !== -1) {
							$(_hash).find('.js-toggle').addClass(a);
							$(_hash).find('.js-toggleBlock').css('display', 'block');
						}
					}, 100);
				});
			}
		},
		pageTop(){
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function(e){
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				var footerPos = $footer.offset().top + $footer.height();
				var thisScroll = $this.scrollTop();
				var pagetopPos = $this.height() + $footer.height() + thisScroll;

				if(thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
				if (footerPos <= pagetopPos) {
					$pagetop.addClass('is-bottom');
				} else {
					$pagetop.removeClass('is-bottom');
				}
			});
		},
		headerSwitch() {
			const $hamburger = FUNCTIONS.va.isGlobal === -1 ? $('.header-hamburger') : $('.global-header-hamburger');
			const $nav = FUNCTIONS.va.isGlobal === -1 ? $('.header-nav') : $('.global-header-nav');
			const $close = $('.header-nav-close');
			const $overlay = FUNCTIONS.va.isGlobal === -1 ? $('.header-overlay') : $('.global-header-overlay');
			const $siteSearch = $('.header-nav-siteSearch');
			let scrollTopNow;

			// リージョン選択 アコーディオン
			$('.js-header-global').on('click', (e) => {
				$(e.currentTarget).toggleClass(a);
				$(e.currentTarget).next('ul').stop().slideToggle();
			});

			// ハンバーガーメニュー
			$hamburger.on('click', (e) => {
				$(e.currentTarget).toggleClass(a);
				if ($nav.hasClass(a)) {
					$nav.removeClass(v);
					$overlay.removeClass(v);
					setTimeout(() => {
						$nav.removeClass(a);
						$overlay.removeClass(a);
					}, 400);
					fixedOffContainer();
				} else {
					fixedOnContainer();
					$overlay.addClass(a);
					$nav.addClass(a);
					setTimeout(() => {
						$overlay.addClass(v);
						$nav.addClass(v);
					});
				}
			});

			// サイト内検索（PCのみ）
			if (FUNCTIONS.va.window.width > 1024) {
				$('.js-header-siteSearch').on('click', () => {
					fixedOnContainer();
					$overlay.addClass(a);
					$siteSearch.addClass(a);
					$close.addClass(a);
					setTimeout(() => {
						$overlay.addClass(v);
						$siteSearch.addClass(v);
						$close.addClass(v);
					});
				});
				$overlay.on('click', () => {
					$siteSearch.removeClass(v);
					$close.removeClass(v);
					$overlay.removeClass(v);
					setTimeout(() => {
						$siteSearch.removeClass(a);
						$close.removeClass(a);
						$overlay.removeClass(a);
					}, 400);
					fixedOffContainer();
				});
				$close.on('click', () => {
					$siteSearch.removeClass(v);
					$close.removeClass(v);
					$overlay.removeClass(v);
					setTimeout(() => {
						$siteSearch.removeClass(a);
						$close.removeClass(a);
						$overlay.removeClass(a);
					}, 400);
					fixedOffContainer();
				});
			}

			const fixedOnContainer = () => {
				scrollTopNow = $(window).scrollTop();
				$('.container').css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				$('body').addClass(f);
			};
			const fixedOffContainer = () => {
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);
				$(window).scrollTop(scrollTopNow);
			};
		},
		headerCurrent() {
			$('.header-nav-main').find('li').each((idx, ele) => {
				if ($(ele).find('a').attr('href') === '/') {
					if (FUNCTIONS.va.pathname === '/' || FUNCTIONS.va.pathname === '/index.html') {
						$(ele).find('a').addClass(a);
					}
				} else {
					if (FUNCTIONS.va.pathname.indexOf($(ele).find('a').attr('href')) === 0) {
						$(ele).find('a').addClass(a);
					}
				}
			});
		},
		footerCopy() {
			let thisY = new Date().getFullYear();
			let copy = $('.footer-copy').text();
			$('.footer-copy').text(copy.replace(/2000-2021/, `2000-${thisY}`));
		},
		sideNavCurrent() {
			if ($('.mod-sideNav-nav').length) {
				$('.mod-sideNav-nav').find('.__link').each((idx, ele) => {
					if (FUNCTIONS.va.pathname === $(ele).attr('href')) {
						$(ele).addClass(a);
					}
				});
			}
		},
		loadDelayScript(){
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.headerSwitch();
			_this.headerCurrent();
			_this.footerCopy();
			_this.sideNavCurrent();
		}
	};

	$(() => FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript());
})(window.jQuery);
